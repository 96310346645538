import React from 'react';
import VideoTemplate   from './VideoTemplate';

class Episod2 extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      videoToPlay : 'episod2',
      videoLibraryUrl : '',
    };
    
  }
  componentDidMount() {

    if(this.state.env === 'localhost'){
      this.setState({videoLibraryUrl : 'https://croppers.mrmlabs.com/media/episodes/'});
    } else if(this.state.env === 'croppers.mrmlabs.com'){
      this.setState({videoLibraryUrl :  'https://croppers.mrmlabs.com/media/episodes/'});
    } else {
      //is prod:
      this.setState({videoLibraryUrl : 'https://croppers.mrmlabs.com/media/episodes/'});
    }

    

  }

  render() {
    return (
      <VideoTemplate>
         <video data-file={this.state.videoToPlay} id={this.state.videoToPlay} webkit-playsinline="true" muted loop playsInline preload="auto" className="preload-video" >
              <source src={this.state.videoLibraryUrl + '/media/episodes/' + this.state.videoToPlay +'.mp4' } type="video/mp4" />
            </video>
       </VideoTemplate>
    )
  }
}
export default Episod2