import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router, Switch  } from 'react-router-dom';
import { createBrowserHistory } from "history";
import './index.css';

import App            from './App';
import Contact        from './Components/Contact';
import LatestEpisode  from './Components/LatestEpisode';
import Notfound       from './Components/Notfound';

import Edit3       from './Components/Edit3';
import Video       from './Components/Video';
import Trailer       from './Components/Trailer';
import Episod1       from './Components/Episod1';
import Episod2       from './Components/Episod2';
// import VideoTemplate       from './Components/VideoTemplate';
//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();


const history = createBrowserHistory()



const routing = (
    
      <Router history={history}>
          <Switch>
              <Route exact path = "/" component = {App} />
              <Route path = "/contact" component = {Contact} />
              <Route path = "/latest-episode" component = {Video} />
              <Route path = "/edit3" component = {Edit3} />
              <Route path = "/video" component = {Video} />
              <Route path = "/trailer" component = {Trailer} />
              {/* <Route exact path = "/episod-:videoId" component = {VideoTemplate} /> */}
              <Route exact path = "/episod1" component = {Episod1} />
              <Route exact path = "/episod2" component = {Episod2} />
              <Route component={Notfound} />
          </Switch>
      </Router>
  
  )

  

  ReactDOM.render(routing, document.getElementById('root'))
  


