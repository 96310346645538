import React from 'react';
import $ from 'jquery';
import { Route, browserHistory, Link, BrowserRouter as Router, Switch, Redirect  } from 'react-router-dom';

import * as gtm from '../Tracker'

import './../css/Video.css';

import  icoLoader from './../images/loading.gif';
import  icoPlay from './../images/ico_play.png';
import  icoPause from './../images/ico_pause.png';
import  icoReplay from './../images/ico_replay.png';
import  icoBack from './../images/ico_bac.png';

import Slider from '@material-ui/core/Slider';
function valuetext(value) {
  console.log('NEW VALUE: ' + value)
  return `-${value}-`;
}

//-- vdeo related begion -- 

var playing = false;
var ended = false;

var timer;
var percent = 0;

function startExperience1(){
  var playing = true;
  $(".replay").hide();
  var mainVideo = document.querySelector('.preload-video')
  mainVideo.play();
}

function startExperience2(){
  var playing = true;
  $(".replay").hide();
  var mainVideo = document.querySelector('.preload-video')
  mainVideo.currentTime = 0;
  mainVideo.play();
}

//-- video related end -- 

class Trailer extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      appLoaded: false,
      orientation: null, // 0 = portrait, 90 = landscape
      fullscreen: false,
      isPlaying: false,
      env : window.location.hostname, 
      videoLibraryUrl : '',
      controlsVisible : true,
      controlsDisabled : false,

      sliderValue: 0,
    };
    
    // This binding is necessary to make `this` work in the callback
    this.handleOrientationChange = this.handleOrientationChange.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleFullScreenToggle = this.handleFullScreenToggle.bind(this);
    this.startExperience = this.startExperience.bind(this);
   

   };



   handleSliderChange = (e, value) => {

    console.log(value);
    this.setState({sliderValue : value})
    var mainVideo = document.querySelector('.preload-video')
   
    var percentage = value/100;
    var vidTime = mainVideo.duration * percentage;
    mainVideo.currentTime = vidTime;

   }

   replay = (e, value) => {
    this.trackEvent('Replay Trailer') ;
    // this.setState({sliderValue : 0, controlsVisible: true})
    this.setState({sliderValue : 0})
    var mainVideo = document.querySelector('.preload-video')
    mainVideo.currentTime = 0;

    var ended = false;
    $(".replay").hide();
    $('.videoControls').removeClass('invisible disabled').addClass('visible');
      
    this.startExperience();
   }
   

   goBack = (e, value) => {
    //this.trackEvent('Replay Trailer') ;
    // this.setState({sliderValue : 0, controlsVisible: true})
    this.setState({sliderValue : 0})
    var mainVideo = document.querySelector('.preload-video')
    mainVideo.pause();
    playing = false;

    document.location.href="/";
   
   }

    
   startExperience = (e, value) => {
      var playing = true;
      $(".replay").hide();
      var mainVideo = document.querySelector('.preload-video')
      mainVideo.currentTime = 0;
      mainVideo.play();
    }
    
    handleFullscreenChange = (e) => {
    let fullscreen = false
    if (document.fullscreenElement ||
      document.mozFullScreenElement||
      document.webkitFullscreenElement ||
      document.msFullscreenElement ||
      document.fullscreen ||
      document.mozFullScreen ||
      document.webkitIsFullScreene ||
      document.fullScreenMode ) {
      fullscreen = true
    }
    this.setState ({fullscreen})
  }
 
  handleFullScreenToggle = (e) => {
    const el = document.documentElement
    if(!this.state.fullscreen) {
      if (el.requestFullscreen) {
        el.requestFullscreen()
      } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen()
      } else if (el.webkitRequestFullscreen) {
        el.webkitRequestFullscreen()
      } else if (el.msRequestFullscreen) {
        el.msRequestFullscreen()
      }
    } 

    if (this.state.isPlaying) 
    { 
      this.setState({isPlaying :false});
    } else { 
      this.setState({isPlaying :true, controlsVisible : false});
    }
  }
 
   
  componentWillMount() {
    this.setState({
      appLoaded: false, 
      
    });

    $('body').addClass('videoStage');
   
 }

componentDidMount() {
  console.log('page load happened');
  if(window.IsTrackable) {
    gtm.pageview();
  }


    setTimeout(()=>{
        this.setState({
          appLoaded: true,
          orientation: window.orientation,
     
        });
        console.log(this.state.appLoaded);
        console.log('DOM rendered');
     
      }, 500);


    if('onorientationchange' in window){
      window.addEventListener("orientationchange", this.handleOrientationChange, false);
    } else if('onresize' in window){
      window.addEventListener("resize", this.handleResize, false);
    }

    document.addEventListener('webkitfullscreenchange', this.handleFullscreenChange, false)
    document.addEventListener('mozfullscreenchange', this.handleFullscreenChange, false)
    document.addEventListener('msfullscreenchange', this.handleFullscreenChange, false)
    document.addEventListener('MSFullscreenChange', this.handleFullscreenChange, false) //IE11
    document.addEventListener('fullscreenchange', this.handleFullscreenChange, false)



    const script = document.createElement("script");
    script.src = "./js/VideoHandler_v103.js?v="+Math.random();
    script.async = true;
    document.body.appendChild(script);

    if(this.state.env === 'localhost'){
      this.setState({videoLibraryUrl : 'https://storage.kfc.ro/'}); // https://croppers.mrmlabs.com/media/episodes/
      console.log('--- IS LOCAHOST -- ');
    } else if(this.state.env === 'croppers.mrmlabs.com'){
      this.setState({videoLibraryUrl :  'https://storage.kfc.ro/'});
    } else {
      //is prod:
      this.setState({videoLibraryUrl : 'https://storage.kfc.ro/'});
    }


    $(".fullscreen").click(function(){
      $('.videoControls:not(.disabled)').toggleClass('invisible').toggleClass('visible');
    });
   
    var mainVideo = document.querySelector('.preload-video')   
    mainVideo.addEventListener("ended", function(_event) {
      var ended = true;
      var playing = false;
      console.log("Experience has ended trailer");
      $('.videoControls').removeClass('visible').addClass('invisible disabled');
      setTimeout(function(){
        $(".replay").fadeIn();
      }, 200)
      
    });
 
      var playing = false;    // temp!!! 
      $('.togglePlay').unbind('click').bind('click', function(event) {
        
        event.preventDefault();
        if(!playing){
          playing = true;
          console.log('not playing');
          startExperience1();
         
          
        } else {
          console.log('playing');
          mainVideo.pause();
          playing = false;
        } 

        return;
      });

      //---- 
      var activeVideo = document.querySelector('.preload-video'); // the current video
      var mdSliderInput = document.querySelector('#mdSlider input');
      var mdSliderThumb = document.querySelector('#mdSlider .MuiSlider-thumb');
      var mdSliderTrack = document.querySelector('#mdSlider .MuiSlider-track');
      
      activeVideo.ontimeupdate = function(){
        var percentage = ( activeVideo.currentTime / activeVideo.duration ) * 100;
        
        mdSliderInput.value = percentage;
        mdSliderThumb.style.left = percentage + '%';
        mdSliderTrack.style.width = percentage + '%';
      };
      //----

      activeVideo.oncanplaythrough = function() {
        console.log("Can play through video without stopping");
      };
 }

 componentWillUnount() {
  window.removeEventListener("orientationchange");
  window.removeEventListener("resize");
  console.log('Unmounting App..');

  document.removeEventListener('webkitfullscreenchange', this.handleFullscreenChange)
  document.removeEventListener('mozfullscreenchange', this.handleFullscreenChange)
  document.removeEventListener('msfullscreenchange', this.handleFullscreenChange)
  document.removeEventListener('MSFullscreenChange', this.handleFullscreenChange)
  document.removeEventListener('fullscreenchange', this.handleFullscreenChange)

}

handleOrientationChange(){
   
    this.setState({
        orientation: window.orientation
    });

 }

 handleResize(){
    this.setState({
      orientation: window.orientation //  0 = portrait, 90 = landscape
    });


 }

 
trackEvent = (_label) => {
  //  onClick={this.trackEvent}
  var trackable = gtm.event({
    action: 'click',
    category: 'video',
    label: _label,
    value: '1'
  })
  
  return;
  
}

    
    render(){
      return [
     
        <div key='trailer' className="wrapper visible" >
              
              <div id="loading_container">
                <img src={icoLoader} alt="play" className="action" /> <br />
                <div id="loadingProgressBar">
                  <span id="loading"></span>
                </div>
              </div>
                
                  
      
              <div className='action_wrapper reload'>
                  <img src={icoReplay} alt="R E P L A Y" className="replay" onClick={this.replay}  />
              </div>

              <div className="fullscreen hidden">

                <div className={this.state.controlsVisible ? 'videoControls visible' : 'videoControls invisible'}>
                
                    <img src={icoBack} alt="Back" className="action back" onClick={this.goBack}  />

                    <div className='action_wrapper'>
                          <img src={this.state.isPlaying ? icoPause : icoPlay}  alt={this.state.isPlaying ? 'P A U S E' : 'P L A Y'} className="togglePlay" onClick={this.handleFullScreenToggle}  />
                    </div>                  
  
                    <Slider
                      defaultValue={0}
                      value={this.state.sliderValue}
                      aria-labelledby="discrete-slider"
                      min={0}
                      max={100}
                      onChange={this.handleSliderChange}
                      onInput={(e, val) => {}}  
                      id = "mdSlider"
                    />
                      {/* 
                        getAriaValueText={valuetext}
                        onDragStop={this.handleSliderChange} */}

                </div>
                 
               
                <video data-file="trailer" id="trailer" webkit-playsinline="true" playsInline preload="auto" className="preload-video" ></video>

              </div>
        </div>
     
      ];
    }
   
}

export default Trailer