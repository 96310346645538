import React from 'react'
import { Route, browserHistory, Link, BrowserRouter as Router, Switch  } from 'react-router-dom';
import  icoPlay from './../images/ico_play.png';

import * as gtm from '../Tracker'


class Home extends React.Component {
      
  componentDidMount() {
    console.log('page load happened');
    if(window.IsTrackable) {
      gtm.pageview();
    }
  }
  
 

  render() {
    return (
      <div className="pageContent">
        <h1>URMĂREȘTE SERIALUL FĂCUT PENTRU VERTICALA TELEFONULUI TĂU.</h1>
        <p>Descoperă imaginea completă mișcând telefonul din poziție verticală în orizontală. Apoi iar pe verticală.</p>
       
        <p></p>
        <Link to="/trailer"><img src={icoPlay} alt="play" className="action playTrailer"  /></Link>
      </div>
    )
  }
}
export default Home