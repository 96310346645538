import React from 'react'
import Layout   from './Layout';


class Edit3 extends React.Component {
  render() {
    return (
      <Layout>
        <div>
          <h2>Edit3</h2>
          <br />
          <div>Welcome</div>
        </div>
       </Layout>
    )
  }
}
export default Edit3