import React from 'react';
import $ from 'jquery';
import { Route, NavLink, Redirect, browserHistory, Link, BrowserRouter as Router, Switch  } from 'react-router-dom';

import './../css/Video.css';
import  icoLoader from './../images/loading.gif';
import  icoPlay from './../images/ico_play.png';
import  icoPause from './../images/ico_pause.png';


class VideoTemplate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      appLoaded: false,
      fullscreen: false,
      isPlaying: false,
      env : window.location.hostname, 
      // videoLibraryUrl : '',
      controlsVisible : true,
    };
   
    this.handleFullScreenToggle = this.handleFullScreenToggle.bind(this);
  
   };
    
  componentWillMount() {
    $('body').addClass('videoStage');
  }


  componentDidMount() {

    setTimeout(()=>{
        this.setState({
          appLoaded: true,
        });
      }, 500);

     // console.log(window.location.pathname.substring(1)); // episod1
    //  var urlKey = window.location.pathname.substring(1);
    //  this.state.availableVideos.forEach( function(video, index){
    //      if( video.urlKey === urlKey){
    //          this.setState({videoToPlay: video.fileName})
    //    }
    //  })
 
    
      // if(this.state.env === 'localhost'){
      //   this.setState({videoLibraryUrl : 'https://croppers.mrmlabs.com/media/episodes/'});
      // } else if(this.state.env === 'croppers.mrmlabs.com'){
      //   this.setState({videoLibraryUrl :  'https://croppers.mrmlabs.com/media/episodes/'});
      // } else {
      //   //is prod:
      //   this.setState({videoLibraryUrl : 'https://croppers.mrmlabs.com/media/episodes/'});
      // }

      
      const script = document.createElement("script");
      script.src = "./VideoHandler_v102.js?v="+Math.random();
      script.async = true;
      document.body.appendChild(script);

      
      document.addEventListener('webkitfullscreenchange', this.handleFullscreenChange, false)
      document.addEventListener('mozfullscreenchange', this.handleFullscreenChange, false)
      document.addEventListener('msfullscreenchange', this.handleFullscreenChange, false)
      document.addEventListener('MSFullscreenChange', this.handleFullscreenChange, false) //IE11
      document.addEventListener('fullscreenchange', this.handleFullscreenChange, false)

  
    
  }

  componentWillUnount() {

    document.removeEventListener('webkitfullscreenchange', this.handleFullscreenChange)
    document.removeEventListener('mozfullscreenchange', this.handleFullscreenChange)
    document.removeEventListener('msfullscreenchange', this.handleFullscreenChange)
    document.removeEventListener('MSFullscreenChange', this.handleFullscreenChange)
    document.removeEventListener('fullscreenchange', this.handleFullscreenChange)

  }


   handleFullscreenChange = (e) => {
    let fullscreen = false
    if (document.fullscreenElement ||
      document.mozFullScreenElement||
      document.webkitFullscreenElement ||
      document.msFullscreenElement ||
      document.fullscreen ||
      document.mozFullScreen ||
      document.webkitIsFullScreene ||
      document.fullScreenMode ) {
      fullscreen = true
    }

    this.setState ({fullscreen})
  }
 
  handleFullScreenToggle = (e) => {
    const el = document.documentElement
    if(!this.state.fullscreen) {
      if (el.requestFullscreen) {
        el.requestFullscreen()
      } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen()
      } else if (el.webkitRequestFullscreen) {
        el.webkitRequestFullscreen()
      } else if (el.msRequestFullscreen) {
        el.msRequestFullscreen()
      }
    } 

    if (this.state.isPlaying) 
    { 
      this.setState({isPlaying :false});
    } else { 
      this.setState({isPlaying :true, controlsVisible : false});
    }
  }
 
   

 toggleControls = () => {
  console.log('Click happened');
  let controlsVisible = true
  if(this.state.controlsVisible)  controlsVisible = false;
  else controlsVisible = true;
  this.setState({controlsVisible});

}
 

    

    render () {
      return [
     
        <div key="ll-01" className="wrapper visible" >

            
              {/* <button onClick={this.handleFullScreenToggle} >{this.state.fullscreen ? 'go full screen' : 'exit full screen'}</button> */}
              
            
              <div id="loading_container">
                <img src={icoLoader} alt="play" className="action" /> <br />
                <div id="loadingProgressBar">
                  <span id="loading"></span>
                </div>
              </div>
                
              {/* /media/episodes/landscape.mp4 */}
              <div className="fullscreen hidden" onClick={this.toggleControls}>
                <div className={this.state.controlsVisible ? 'videoControls visible' : 'videoControls invisible'}>
                  <div className={this.state.isPlaying ? 'action togglePlay ' : 'action togglePlay '} onClick={this.handleFullScreenToggle} >
                    <img src={this.state.isPlaying ? icoPause : icoPlay} alt=""/>
                  </div>

                  <div id="custom-seekbar">
                    <span></span>
                  </div>
                  
                  <div>
                  <Link to="/episod1">  VEZI EPISOD 1   </Link> 
                  <Link to="/episod2">  VEZI EPISOD 2   </Link>
                    {/* {
                      this.state.availableVideos.map((video, index) => (
                        <h5 key={index}>
                        
                          <Link to={`/episod-${video.id}`}>Vezi episodul {video.id}</Link>
                        </h5>
                      ))
                    } */}
                  </div>
                </div>
               
               

                {this.props.children}
               

                {/* <RequestedVideo videoId={this.state.requestedVideo} /> */}
                {/* <RequestedVideo videoName={this.state.videoToPlay} /> */}

                {/* <video data-file={this.state.requestedVideo} id={this.state.requestedVideo} webkit-playsinline="true" muted loop playsInline preload="auto" className="preload-video" >
                  <source src={this.state.videoLibraryUrl + '/media/episodes/' + this.state.requestedVideo +'.mp4' } type="video/mp4" />
                </video> */}
{/* 
                <video data-file={this.state.videoToPlay} id={this.state.videoToPlay} webkit-playsinline="true" muted loop playsInline preload="auto" className="preload-video" >
                  <source src={this.state.videoLibraryUrl + '/media/episodes/' + this.state.videoToPlay +'.mp4' } type="video/mp4" />
                </video> */}

              </div>
        </div>
     
      ];
    }
   
}

export default VideoTemplate