import React from 'react';
import $ from 'jquery';

import './../css/Video.css';

class Video extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      appLoaded: false,
      orientation: null, // 0 = portrait, 90 = landscape

    };
    
    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
    this.handleOrientationChange = this.handleOrientationChange.bind(this);
    this.handleResize = this.handleResize.bind(this);
   }


  componentWillMount() {
    this.setState({
      appLoaded: false, 
      
    });
      
    
 }

componentDidMount() {
    setTimeout(()=>{
        this.setState({
          appLoaded: true,
          orientation: window.orientation,
     
        });
        console.log(this.state.appLoaded);
        console.log('DOM rendered');
      }, 500);


    if('onorientationchange' in window){
      window.addEventListener("orientationchange", this.handleOrientationChange, false);
    } else if('onresize' in window){
      window.addEventListener("resize", this.handleResize, false);
    }
  
     
 }

 componentWillUnount() {
  window.removeEventListener("orientationchange");
  window.removeEventListener("resize");
  console.log('Unmounting App..');
}

handleOrientationChange(){
   
    this.setState({
        orientation: window.orientation
    });
 }

 handleResize(){
    this.setState({
      orientation: window.orientation
    });
 }

 

handleClick() {
      this.setState(prevState => ({
        isToggleOn: !prevState.isToggleOn
      }));

      var video = $('.preload-video').get(0);
      if (video.paused ) {
          video.play();
          this.innerHTML = "Pause";
      } else {
          video.pause();
          this.innerHTML = "Play";
      }

}

    
    render(){
      return [
     
        <div key="ll-01" className={this.state.appLoaded ? 'wrapper visible' : 'wrapper'} >
           
              {/* <button id="myBtn" onClick={this.handleClick} >{this.state.isToggleOn ? 'PLAY' : 'PAUSE'}</button> */}
              
              <button id="btn_play"> --- PLAY --- </button>
              <div className="fullscreen">
                <video data-file="landscape" id="landscape" webkit-playsinline="true" controls playsInline loop preload="auto" className="preload-video" poster="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPk5QEAACwAGyN5KPMAAAAASUVORK5CYII=" >
                    <source src="/media/episodes/landscape.mp4" type="video/mp4" />
                </video>
              
              </div>

        </div>
     
      ];
    }
   
}

export default Video