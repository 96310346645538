import React from 'react';
import ReactDOM from 'react-dom';
import { Route, browserHistory, Link, BrowserRouter as Router, Switch  } from 'react-router-dom';

import $ from 'jquery';
import './../App.css';

class LatestEpisode extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      appLoaded: false,
      orientation: null, // 0 = portrait, 90 = landscape
      
      videoLandscape : null,
      videoPortrait : null,
    };
    
    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
    this.handleOrientationChange = this.handleOrientationChange.bind(this);
    this.handleResize = this.handleResize.bind(this);
   }


  componentWillMount() {
    this.setState({
      appLoaded: false, 
      
    });
      
    
 }

componentDidMount() {
    setTimeout(()=>{
        this.setState({
          appLoaded: true,
          orientation: window.orientation,
         
          videoLandscape: document.getElementById("video_landscape"),
          videoPortrait: document.getElementById("video_portrait"),
        });
        console.log(this.state.appLoaded);
        console.log('DOM rendered');
      }, 500);


    if('onorientationchange' in window){
      window.addEventListener("orientationchange", this.handleOrientationChange, false);
    } else if('onresize' in window){
      window.addEventListener("resize", this.handleResize, false);
    }
  
     
 }

 componentWillUnount() {
  window.removeEventListener("orientationchange");
  window.removeEventListener("resize");
  console.log('Unmounting App..');
}

handleOrientationChange(){
   
    this.setState({
        orientation: window.orientation
    });
 }

 handleResize(){
    this.setState({
      orientation: window.orientation
    });
 }

 

handleClick() {
     
      console.log('click');
      console.log(this.state.orientation);
      this.setState(prevState => ({
        isToggleOn: !prevState.isToggleOn
      }));
    
    // var videoLandscape = document.getElementById("video_landscape"); // $('#video_landscape').get(0); 
   //  var videoPortrait =  document.getElementById("video_portrait"); // $('#video_portrait').get(0);
    
    // alert("click paused is " + videoLandscape.paused);
       
      if (this.state.videoLandscape.paused) {
        this.state.videoLandscape.play();
        this.state.videoPortrait.play();

      } else {
        this.state.videoLandscape.pause();
        this.state.videoPortrait.pause();
      
      }
}

    
    render(){
      return [
     
        <div key="ll-01" className={this.state.appLoaded ? 'wrapper visible' : 'wrapper'} >
           
            <div className="App" >
               
               <div className="fullscreen-bg">
                <video  type="video/mp4" webkit-playsinline="true" playsInline loop preload="none" className="fullscreen-bg__video verticalVideo" muted={this.state.orientation === 0 ? false:true}   id="video_portrait"  poster="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPk5QEAACwAGyN5KPMAAAAASUVORK5CYII=">
                  <source  src="/media/episodes/test_vertical.mp4"  type="video/mp4"></source>
                </video>

                <video  type="video/mp4" webkit-playsinline="true" playsInline loop preload="none" className="fullscreen-bg__video landscapeVideo" muted={this.state.orientation === 90 ? false:true} data-orientation={this.state.orientation} id="video_landscape" poster="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPk5QEAACwAGyN5KPMAAAAASUVORK5CYII=">
                  <source src="/media/episodes/test_orizontal.mp4" type="video/mp4"></source>
                </video>
              </div>

              <button id="myBtn" onClick={this.handleClick} >{this.state.isToggleOn ? 'PLAY' : 'PAUSE'}</button>
            
               
            </div>
        </div>
     
      ];
    }
   
}

export default LatestEpisode