export const GA_TRACKING_ID = 'UA-149604697-1'

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = url => {
    console.log(' ------ pageview ---- '); // window.location.pathname.substring(1)
   
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id="+ GA_TRACKING_ID;
    script.async = true;
    document.body.appendChild(script);

    const jsCode = `
              window.dataLayer = window.dataLayer || [] ;
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GA_TRACKING_ID}', {
                page_location: ${url}
            });
      `;
    new Function(jsCode)();
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label }) => {

    console.log(' --- sending event --- ' + label);
   
    const jsEventCode = `
            window.dataLayer = window.dataLayer || [] ;
            function gtag(){dataLayer.push(arguments);}
            gtag('event', '${action}', {
                    event_category: '${category}',
                    event_label: '${label}'
                });
            `;
    new Function(jsEventCode)();

}