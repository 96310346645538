import React from 'react'
import Layout   from './Layout';
// const Notfound = () => <h1>Not found</h1>

class Notfound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectUrl: window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
        };
       
    }
    
    componentDidMount() {
      window.addEventListener('load', function() {
        window.location.href = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port;
        });
    }

    render() {
        return (
          <Layout>
            <div>
                <h1>AWWW...DON’T CRY.</h1>
                <p>
                    It's just a 404 Error! <br /><br />
                    What you’re looking for may have been misplaced in Long Term Memory.
                </p>
                <p>
                    <a href={this.state.redirectUrl}> Click here to return to civilization</a>
                </p>
            </div>
        </Layout>
        )
    }
}
export default Notfound