import React from 'react';
import Layout   from './Components/Layout';
import Home     from './Components/Home';

class App extends React.Component {
 
render(){
   return (
      <Layout> 
        <Home />
      </Layout>
    )
  }
}

export default App;
