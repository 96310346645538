
import Layout   from './Layout';
import React from 'react'


class Contact extends React.Component {
  
  render() {
    return (
      <Layout>
        <div key='c-1'>
          <h1>contact page</h1>
          <br />
          <div>hello from contact page</div>

            {/* slider begin  */}
            
            {/* <br /><br /><br />
                    <div className="mdc-slider" tabIndex="0" role="slider"
                      aria-valuemin="0" aria-valuemax="100" aria-valuenow="0" data-step="1" 
                      aria-label="Select Value">
                    <div className="mdc-slider__track-container">
                      <div className="mdc-slider__track"></div>
                    </div>
                    <div className="mdc-slider__thumb-container">
                      <svg className="mdc-slider__thumb" width="21" height="21">
                        <circle cx="10.5" cy="10.5" r="7.875"></circle>
                      </svg>
                      <div className="mdc-slider__focus-ring"></div>
                    </div>
                  </div>
              */}

                  
                  {/* slider end   */}


        </div>
       </Layout>
    )
  }
}
export default Contact