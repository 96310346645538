import React from 'react'
import { Route, browserHistory, Link, BrowserRouter as Router, Switch  } from 'react-router-dom';
import the_croppers_logo from "../images/the_croppers_logo.png";


class Header extends React.Component {
  
  render() {
    return [
     <div key="h-01">
         
        <div key="hc-01"><Link to="/"><img src={the_croppers_logo} alt ="the CROPPERS" id="logo" /></Link></div>
      </div>
    ]
  }
}

export default Header