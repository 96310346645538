//--- SW part : -------------

export const check = () => {
  if (!('serviceWorker' in navigator)) {
    return false;
    //throw new Error('No Service Worker support!')
  }
  if (!('PushManager' in window)) {
    return false;
    // throw new Error('No Push API Support!')
  }

  return true;
 
}

// handle notifications: --------------

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);


 var API_URL ='https://api-croppers.mrmlabs.com/';
 
// urlB64ToUint8Array is a magic function that will encode the base64 public key
// to Array buffer which is needed by the subscription option
const urlB64ToUint8Array = base64String => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')
  const rawData = atob(base64)
  const outputArray = new Uint8Array(rawData.length)
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export const checkNotificationPermission  =  () => {
  try {
    return window.Notification.permission;
  } catch (error) {
      // not supported, do nothing   
      // probably safari or old browser  
      return false;
                                                                                                                                                                                               
    }  
}

const requestNotificationPermission = async () => {
  try {
        const permission = await window.Notification.requestPermission();

        // value of permission can be 'granted', 'default', 'denied'
        // granted: user has accepted the request
        // default: user has dismissed the notification permission popup by clicking on x
        // denied: user has denied the request.
        if(permission !== 'granted'){
            alert("Notifications blocked. Please enable them in your browser.");
            // throw new Error('Permission not granted for Notification');
           return false;

        } else {
          // ask user to subscribe:
          subscribe();
        }
  } catch (error) {
         // not supported, do nothing     
        console.log('notifications not supported');                                                                                                                                                                                           
  }     
}

const showLocalNotification = (title, body, swRegistration) => {
  const options = {
      body,
      // here you can add more properties like icon, image, vibrate, etc.
  };
  swRegistration.showNotification(title, options);
}

export const main =  () => { // main is an async function so that I can use await for registerServiceWorker
  if(check()){
    return requestNotificationPermission();
  } 
}


//------------------- user part:


export const subscribe = async () => {
  const swRegistration = await navigator.serviceWorker.ready
    .then(async function(swRegistration) {

          // const SERVER_URL = 'http://104.248.252.234:9999/pushKey';
          const SERVER_URL = API_URL + 'pushKey';
          const publicVapidKey = await fetch(SERVER_URL, {method: 'get'})
                .then(response => { return  response.text();   })
                .then(publicVapidKey => { return publicVapidKey;  });

          const convertedVapidKey = urlB64ToUint8Array(publicVapidKey)
          
          return swRegistration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: convertedVapidKey
          }).then(function(subscription) {
              console.log(' >>>>>  Subscription from : ', window.location.host);
              // console.log('Subscription', subscription);
              // console.log('Subscribed', subscription.endpoint);
              const SERVER_URL =  API_URL + 'subscribe';
              return fetch(SERVER_URL, {
                method: 'post',
                headers: {
                  'Content-type': 'application/json'
                },
                body: JSON.stringify({
                  subscription: subscription,
                  source: window.location.host
                })
              });
          })
         
         
    })
    .then(setUnsubscribeButton);
}



export const unsubscribe = async () => {
  await navigator.serviceWorker.ready.then(
    registration => {
        //Find the registered push subscription in the service worker
        registration.pushManager
          .getSubscription()
          .then(subscription => {
              if (!subscription) {
                return 
                //If there isn't a subscription, then there's nothing to do
              }

              console.log('Unsubscribing', subscription.endpoint);
              const SERVER_URL =  API_URL + 'unsubscribe';
              fetch(SERVER_URL, {
                method: 'post',
                headers: {
                  'Content-type': 'application/json'
                },
                body: JSON.stringify({
                  subscription: subscription
                })
              })

              subscription.unsubscribe().then(() => {
                  console.log('unsubscribed');
              }) 
            
           
        
      })
      .catch((err) => console.error(err))
  })
  .then(setSubscribeButton);

}



function setSubscribeButton() {

  
}

function setUnsubscribeButton() {
  
 
}
