import React from 'react'
import { Link, BrowserRouter as Router } from 'react-router-dom';
import * as pushManager from '../pushManager_v101'

import  ctaSubscribe from './../images/cta-subscribe.png';
import  icoInsta from './../images/ico_insta.png';
import  icoYoutube from './../images/ico_youtube.png';
import  icoFb from './../images/ico_fb.png';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationPermission: '', 
      pushPermission: false, 
      isIOS : !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform),
    };
    
   
   }

  componentWillMount() {

    this.setState({
      notificationPermission: pushManager.checkNotificationPermission()
    });
    
    
 }

  componentDidMount() {

      if(this.state.notificationPermission === 'granted'){
          this.setState({
            pushPermission: true
          });
      }
    
 }

 componentWillUnount() {
    window.removeEventListener("orientationchange");
    window.removeEventListener("resize");
}

 handleOrientationChange(){
   
    this.setState({
        orientation: window.orientation
    });
 }

 handleResize(){
    this.setState({
      orientation: window.orientation
    });
 }

 


 addSubscriber(){
    console.log('adding new subscriber...');
    pushManager.main().then( () => {
        this.setState({
            notificationPermission: pushManager.checkNotificationPermission()
        });

        if(this.state.notificationPermission === 'granted'){
            this.setState({
              pushPermission: true
          });
        } 
    })
   
  }

  unsubscribeFromPush(){
  
    pushManager.unsubscribe().then( () => {
      // poate do smth here 
    })

    this.setState({
      pushPermission: false
    });
  }

  subscribeToPush(){
    pushManager.subscribe().then( () => {
      // poate do smth here 
    })
    this.setState({
        pushPermission: true
    });
  }


  render() {
    return [
      <div key="footer" className="footer">
      
       { ( this.state.notificationPermission !== 'granted' && !this.state.isIOS) ?  
               <div className="subscribe" id="subscriptionButton" onClick={this.addSubscriber.bind(this)} ><img src={ctaSubscribe} alt="" className="action" /></div>     
        : '' }
       
     

        <div className="nav">
            <a href="https://www.instagram.com/kfcromania/?hl=en" target="_blank" rel="noopener noreferrer"><img src={icoInsta} alt="" className="action" /></a>
            <a href="https://www.youtube.com/user/kfcro" target="_blank" rel="noopener noreferrer"><img src={icoYoutube} alt="" className="action" /></a>
            <a href="https://www.facebook.com/KFC.Romania/ " target="_blank" rel="noopener noreferrer"><img src={icoFb} alt="" className="action" /></a>
       </div>
       
       <div className="nav text">
              <Link to="/" key="nav-01">Acasă</Link>
              <a href="mailto:text@test.com" target="_blank" rel="noopener noreferrer">Contact</a>
              <a href="#" target="_blank" rel="noopener noreferrer">Termeni și condiţii</a>
              <a href="#" target="_blank" rel="noopener noreferrer">Politica de confidenţialitate</a>
              <a href="#" target="_blank" rel="noopener noreferrer">Politica de utilizare cookies</a>
              {/* <Link to="/video" key="nav-02">Vezi ultimul episod</Link> */}
              {/* <Link to="/users" key="nav-04">404 page</Link> */}
        </div>
        <div className="disclaimer">  Copyright &copy; 2019  </div>
 

       </div>
    ]
  }
}
export default Footer